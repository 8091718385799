<template>
  <div class="vacation" v-if="currentUser._id && vt._id" ref="vacationHourly">
    <p v-if="vh.annulled" class="annulled-p">ОТСЪСТВИЕТО Е АНУЛИРАНО</p>
    <div class="vacation__content no-print">
      <p class="vacation__content__subtitle">
        <strong>ТИП ПОЧАСОВО ОТСЪСТВИЕ</strong>
      </p>
      <!-- (regularEmployee && activeCompany !== 'КЦМ 2000') -->
      <VacationHourlyType
        v-model="vh.type"
        :disabled="vh._id && vh.allowedByHR"
        :template="vt"
        :availableVacations="regularEmployee && activeCompany !== 'КЦМ 2000' ? ['0030', '0025', '0012'] : false"
        @input="typeChanged"
      />
    </div>
    <div class="vacation__content vacation__content--official-notice" v-if="vh.type">
      <div class="row row--space-between">
        <p class="vacation__content__title">
          <span v-if="vt[`titleOne${vh.type}`]">
            {{ vt[`titleOne${vh.type}`] }}
          </span>
          <template v-if="vt[`titleTwo${vh.type}`]">
            <br />
            <span>
              {{ vt[`titleTwo${vh.type}`] }}
            </span>
          </template>
        </p>
        <Input
          class="input-wrapper--1 input-wrapper--white input-wrapper--print-smap"
          placeholder="№"
          :value="vh.number ? `№ ПО${vh.number}` : ''"
          :disabled="true"
        />
      </div>
      <div class="row">
        <Input
          class="input-wrapper--white"
          :label="vt[`userName${vh.type}`]"
          :placeholder="vt[`userNamePlaceholder${vh.type}`]"
          v-model="vh.user"
          prop="name"
          :disabled="
            vh._id ||
            (!currentUser.roles.admin && !currentUser.roles.hr && !currentUser.roles.supervisor) ||
            activeCompany === 'КЦМ 2000'
          "
          :list="employees"
          @item-selected="employeeSelected"
          @input="employeeInput"
          @focus="getEmployees"
          @search="getEmployees"
        />
      </div>
      <div
        class="row"
        v-if="
          activeCompany !== 'КЦМ 2000' &&
          (vh.type === '0025' || vh.type === '0012' || vh.type === '0020' || vh.type === '0045')
        "
      >
        <Input
          type="textarea"
          class="input-wrapper--white input-wrapper--no-flex-print"
          :label="vt[`reasonForVacation${vh.type}`]"
          :placeholder="vt[`reasonForVacationPlaceholder${vh.type}`]"
          :maxlength="165"
          :disabled="vh._id"
          v-model="vh.reasonForVacation"
        />
      </div>
      <div class="row row--mobile-wrap row--mobile-space-between" v-if="vh.user">
        <Input
          class="input-wrapper--white input-wrapper--1 input-wrapper--mobile-40 input-wrapper--third"
          :label="vt[`userNumber${vh.type}`]"
          :placeholder="vt[`userNumberPlaceholder${vh.type}`]"
          :value="vh.user ? vh.user.number : null"
          disabled="true"
        />
        <Input
          class="input-wrapper--white input-wrapper--3 input-wrapper--mobile-order-3 input-wrapper--full"
          :label="vt[`userPosition${vh.type}`]"
          :placeholder="vt[`userPositionPlaceholder${vh.type}`]"
          :value="vh.user.position ? vh.user.position.name : null"
          disabled="true"
        />
      </div>
      <p class="vacation__content__subtitle">
        <template v-if="vh.type === '0030'">
          {{ vt[`text1Sub1${vh.type}`] }}
          {{ vh.supervisorFirst ? vh.supervisorFirst.name : '&lt;&lt;Име на прекия ръководител&gt;&gt;' }},
          <br />
        </template>
        {{ vt[`text1${vh.type}`] }}
      </p>
      <div class="row row--space-between row--margin-top row--date-and-hours">
        <template v-if="vh.type === '0020' || vh.type === '0019' || vh.type === '0025' || vh.type === '0030'">
          <div class="date-input-wrapper">
            <Input
              class="input-wrapper--white input-wrapper--100"
              :label="vt[`date${vh.type}`]"
              :datepicker="true"
              :disabled="vh._id && vh.allowedByHR && !allowDatesEdit"
              v-model="vh.dateFrom"
              :disabledDates="disabledDates"
              @input="autoSelectDateTo(), getShifts()"
            />
          </div>

          <div class="hours-input-wrapper">
            <Input
              class="input-wrapper--white input-wrapper--100"
              :label="vt[`hourFrom${vh.type}`]"
              :timepicker="true"
              :disabled="vh._id && vh.allowedByHR && !allowDatesEdit"
              v-model="vh.hourFrom"
            />
            <Input
              class="input-wrapper--white input-wrapper--100"
              :label="vt[`hourTo${vh.type}`]"
              :timepicker="true"
              :disabled="vh._id && vh.allowedByHR && !allowDatesEdit"
              v-model="vh.hourTo"
            />
          </div>
        </template>

        <div class="date-input-wrapper" v-else>
          <Input
            class="input-wrapper--white input-wrapper--100"
            :label="vt[`dateFrom${vh.type}`]"
            :datepicker="true"
            :disabled="vh._id && vh.allowedByHR && !allowDatesEdit"
            v-model="vh.dateFrom"
            :disabledDates="disabledDatesFrom"
            @input="getShifts"
          />
          <Input
            class="input-wrapper--white input-wrapper--100"
            :label="vt[`dateTo${vh.type}`]"
            :datepicker="true"
            :disabled="vh._id && vh.allowedByHR && !allowDatesEdit"
            v-model="vh.dateTo"
            :disabledDates="disabledDatesTo"
            @input="getShifts"
          />
        </div>
        <div class="schedule-viewer-wrapper" v-if="activeCompany === 'КЦМ АД'">
          <div class="schedule-viewer">
            <label>{{ vt[`shift${vh.type}`] }}</label>
            <div class="fake-input" :class="{ 'fake-input--bigger': vh.shifts.length > 5 }">
              <template v-for="(s, j) in vh.shifts">
                <span
                  class="circle circle--solid circle--print"
                  :class="`circle--${s ? s.i : null}`"
                  :key="`shift-sp-${j}`"
                >
                  <template v-if="s">
                    {{ s.name }}
                  </template>
                  <template v-else>П</template>
                </span>
              </template>
              <!-- <template
                v-if="
                  vh.type === '0020' || vh.type === '0019' || vh.type === '0025' || vh.type === '0030'
                "
              > -->
              <span v-if="vh.shifts.length === 1">{{ vh.shifts[0] ? vh.shifts[0].hours : '' }}</span>
              <!-- </template> -->
            </div>
          </div>
        </div>
      </div>
      <template v-if="vh.type !== '0030'">
        <p class="vacation__content__subtitle">
          <span v-if="vt[`text2part1${vh.type}`]">
            {{ vt[`text2part1${vh.type}`] }}
          </span>
          <span v-if="vt[`text2part2${vh.type}`]">
            <strong>{{ vt[`text2part2${vh.type}`] }}</strong>
          </span>
          <span v-if="vt[`text2part3${vh.type}`]">
            {{ vt[`text2part3${vh.type}`] }}
          </span>
        </p>
        <p class="vacation__content__subtitle vacation__content__subtitle--textarea" v-if="vt[`textarea${vh.type}`]">
          {{ vt[`textarea${vh.type}`] }}
        </p>
      </template>
      <div class="row row--space-between row--align-bottom" v-else>
        <Input
          class="input-wrapper--white input-wrapper--1"
          :label="vt[`dateOfSigning${vh.type}`]"
          :datepicker="true"
          :disabled="vh._id && vh.allowedByHR"
          v-model="vh.dateOfSigning"
        />
        <p class="half">
          <span v-if="vt[`text2part1${vh.type}`]">
            {{ vt[`text2part1${vh.type}`] }}
          </span>
          <span v-if="vt[`text2part2${vh.type}`]">
            <strong>{{ vt[`text2part2${vh.type}`] }}</strong>
          </span>
        </p>
      </div>
      <div class="row row--report-button" v-if="vh.type === '0016'">
        <Button
          class="button--orange button--medium no-print"
          @click="openReportsModal()"
          :disabled="!vh._id || !vh.finalizedAt || vh.annulled"
          >Отчитане на свършената работа</Button
        >
      </div>
      <div class="row row--space-between row--margin-top row--align-top row--mobile-signatures">
        <Input
          v-if="vh.type !== '0030' && vh.type !== '0016'"
          class="input-wrapper--white input-wrapper--1"
          :label="vt[`dateOfSigning${vh.type}`]"
          :datepicker="true"
          :disabled="vh._id && vh.allowedByHR"
          v-model="vh.dateOfSigning"
        />
        <Signature
          v-else-if="vh.type === '0030'"
          :label="vt[`employee${vh.type}`]"
          :name="vh.user ? vh.user.name : ''"
          :signature="vh.signatures.user"
          :disabled="vh._id && vh.signatures.user"
          @signed="vh.signatures.user = $event"
        />
        <Signature
          v-if="activeCompany !== 'КЦМ 2000' ? vh.type !== '0025' && vh.type !== '0012' : true"
          :label="vt[`supervisor${vh.type}`]"
          :name="vh.supervisorFirst ? vh.supervisorFirst.name : ''"
          :signature="vh.signatures.supervisorFirst"
          :disabled="regularEmployee || disabledSupervisorFirstSignature"
          @signed="vh.signatures.supervisorFirst = $event"
        />
        <Signature
          v-if="vh.type === '0016'"
          :label="vt[`employee${vh.type}`]"
          :name="vh.user ? vh.user.name : ''"
          :signature="vh.signatures.user"
          :disabled="vh._id && vh.signatures.user"
          @signed="vh.signatures.user = $event"
        />
      </div>
      <div
        v-if="activeCompany !== 'КЦМ 2000' && (vh.type === '0025' || vh.type === '0012')"
        class="row row--space-between row--margin-top row--align-top row--mobile-signatures"
      >
        <Signature
          :label="vt[`employee${vh.type}`]"
          :name="vh.user ? vh.user.name : ''"
          :signature="vh.signatures.user"
          :disabled="vh._id && vh.signatures.user"
          @signed="vh.signatures.user = $event"
        />
        <Signature
          :label="vt[`supervisor${vh.type}`]"
          :name="vh.supervisorFirst ? vh.supervisorFirst.name : ''"
          :signature="vh.signatures.supervisorFirst"
          :disabled="regularEmployee || disabledSupervisorFirstSignature"
          @signed="vh.signatures.supervisorFirst = $event"
        />
      </div>
    </div>

    <div
      class="vacation__content vacation__content--second"
      v-if="vh._id && vh.needsSecondConfirmation && preEditVh.signatures.supervisorFirst"
    >
      <div class="row row--space-between row--mobile-column-center">
        <PermissionSwitch
          v-model="vh.allowedBySupervisorSecond"
          :yes="vt[`iAllow${vh.type}`]"
          :no="vt[`iDontAllow${vh.type}`]"
          :disabled="preEditVh.signatures.supervisorSecond || disabledSupervisorSecondSignature"
        />
        <Signature
          :label="vt[`supervisorSecond${vh.type}`]"
          :name="vh.supervisorSecond ? vh.supervisorSecond.name : ''"
          :signature="vh.signatures.supervisorSecond"
          :disabled="regularEmployee || disabledSupervisorSecondSignature"
          @signed="$set(vh.signatures, 'supervisorSecond', $event)"
        />
      </div>
    </div>

    <div
      class="vacation__content vacation__content--hr no-print"
      v-if="
        vh._id &&
        vh.showHRPanel &&
        (vh.type === '0020' || vh.type === '0019' || vh.type === '0025' || vh.type === '0030') &&
        !regularEmployee &&
        isAdminOrHr
      "
    >
      <p class="vacation__content__title center">
        <strong>Решение на Човешки ресурси<br />за финално одобрение</strong>
      </p>
      <PermissionSwitch
        v-model="vh.allowedByHR"
        stateless="true"
        edit="true"
        :disabled="vh.finalizedAt || !currentUser.roles.hr"
        @input="allowedByHRChanged"
      />
      <p class="vacation__content__mini-text">
        Изберете ДА за одобрение, или РЕДАКЦИЯ за да редактирате отсъствието.<br />След като редактирате отсъствието,
        натиснете пак ДА, и запишете промените.
      </p>
    </div>
    <footer class="vacation__buttons no-print" v-if="!vh.annulled && vh.type">
      <div>
        <Button
          class="button--red"
          :disabled="!vh._id || loading || (!currentUser.roles.hr && !currentUser.roles.admin)"
          @click="save('annul')"
          >Анулирай</Button
        >
        <Button class="button--dark" @click="print">Печат</Button>
      </div>
      <div>
        <transition name="fade">
          <Loading :success="success" v-if="loading" />
        </transition>
        <Button class="button--blue" :disabled="loading" @click="save" v-if="saveButtonVisible(vh)">Запиши</Button>
        <Button
          class="button--blue"
          @click="update('dates')"
          :disabled="loading"
          v-else-if="allowDatesEdit && !vh.annulled && (currentUser.roles.admin || currentUser.roles.hr)"
          >Запиши</Button
        >
        <Button
          class="button--darker-blue"
          @click="allowingDatesEdit"
          v-else-if="vh.finalizedAt && !vh.annulled && (currentUser.roles.admin || currentUser.roles.hr)"
          >Редактирай</Button
        >
      </div>
    </footer>

    <div
      v-for="day of datesPeriod"
      :key="day.fullDate"
      class="reports-print vacation__content vacation__content--official-notice"
      v-show="
        (activeCompany === 'КЦМ АД' ? day.shift : !day.holiday && !day.weekend) && today >= new Date(day.fullDate)
      "
    >
      <p class="vacation__content__title">
        {{ day.report ? 'ОТЧЕТ ЗА СВЪРШЕНА РАБОТА ЗА ДЕНЯ' : 'НЯМА ОТЧЕТ ЗА' }} - {{ day.fullDate | formatDate }}
      </p>
      <section v-if="!day.report">Служителят не се е отчел в този период</section>
      <section v-for="(t, i) of day.report.tasks" :key="`${t}-${i}`" v-else>
        <p>
          Задача {{ i + 1 }} - <span>{{ t.description }}</span>
        </p>
        <p>
          Категория <span>{{ t.category.name }}</span>
        </p>
        <p>
          За време <span>{{ t.time }}ч.</span>
        </p>
      </section>
    </div>

    <ReportsPeriodModal
      v-if="isReportModalOpen"
      :reports="reports"
      :vh="vh"
      @close="(isReportModalOpen = false), getReports(vh.user)"
      @getReports="getReports(vh.user)"
    />
  </div>
</template>

<script>
import Input from '@/components/inputs/Input';
import PermissionSwitch from '@/components/inputs/PermissionSwitch';
import Signature from '@/components/signature/Signature';
import VacationHourlyType from './VacationHourlyType';
import Button from '@/components/Button';
import ReportsPeriodModal from '@/components/ReportsPeriodModal';
import Loading from '@/components/Loading';
import { mapGetters } from 'vuex';
export default {
  components: {
    Input,
    PermissionSwitch,
    Signature,
    VacationHourlyType,
    Button,
    ReportsPeriodModal,
    Loading,
  },
  watch: {
    vh() {
      if (this.vh._id || (this.vh.dateFrom && this.vh.dateTo)) {
        this.getReports(this.vh.user);
      }
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
    vh() {
      return this.vacationHourly;
    },
    vt() {
      return this.vh.template;
    },
    isAdminOrHr() {
      return this.currentUser.roles.admin || this.currentUser.roles.hr;
    },
    regularEmployee() {
      return (
        this.currentUser.roles.employee &&
        !this.currentUser.roles.admin &&
        !this.currentUser.roles.hr &&
        !this.currentUser.roles.supervisor
      );
    },
    isOwnVh() {
      if (!this.vh.user) return false;
      else return this.currentUser._id === this.vh.user._id;
    },
    disabledSupervisorFirstSignature() {
      const sf = this.vh.supervisorFirst ? this.vh.supervisorFirst._id : null;

      return this.currentUser._id !== sf && !this.currentUser.roles.admin && !this.currentUser.roles.hr;
    },
    disabledSupervisorSecondSignature() {
      const ss = this.vh.supervisorSecond ? this.vh.supervisorSecond._id : null;

      return this.currentUser._id !== ss && !this.currentUser.roles.admin && !this.currentUser.roles.hr;
    },
    disabledDates() {
      const disabled = {};
      if (!this.currentUser.roles.hr && !this.currentUser.roles.admin) {
        const to = new Date();

        if (to.getDate() <= 2) {
          to.setMonth(to.getMonth() - 1);
        }

        to.setDate(0);
        to.setHours(23, 59, 59, 999);

        disabled.to = to;
      }

      return disabled;
    },
    disabledDatesFrom() {
      const disabled = {
        ...this.disabledDates,
      };
      if (this.vh.dateTo) disabled.from = this.vh.dateTo;
      return disabled;
    },
    disabledDatesTo() {
      const disabled = {
        ...this.disabledDates,
      };
      if (this.vh.dateTo && disabled.to < this.vh.dateFrom) disabled.to = this.vh.dateFrom;
      return disabled;
    },
  },
  data() {
    return {
      isReportModalOpen: false,
      loading: false,
      success: undefined,
      today: new Date(new Date().setHours(12, 0, 0, 0)),
      dates: [],
      datesPeriod: [],
      reports: [],
      employees: [],
      allowDatesEdit: false,
      preEditVh: [],
      vacationHourly: {
        dateOfSigning: new Date(),
        allowedByHR: true,
        template: {},
        shifts: [],
        signatures: {
          user: null,
          supervisorFirst: null,
          supervisorSecond: null,
        },
      },
    };
  },
  methods: {
    saveButtonVisible(vh) {
      return (
        (!vh._id || !vh.finalizedAt) &&
        !vh.annulled &&
        (vh.showHRPanel ? this.isAdminOrHr : true) &&
        (this.preEditVh.needsSecondConfirmation ? this.preEditVh.allowedBySupervisorSecond : true)
      );
    },
    openReportsModal() {
      if (this.vh.user._id === this.currentUser._id || this.currentUser.roles.admin || this.currentUser.roles.hr) {
        this.getReports(this.vh.user);
        this.isReportModalOpen = true;
      } else {
        this.$snotify.warning(`Само ${this.vh.user.name} може да създава/редактира отчети`);
        return false;
      }
    },
    print() {
      window.print();
    },
    typeChanged() {
      this.$router.push({ query: { type: this.vh.type } });
    },
    resetVacation() {
      this.vacationHourly = {
        dateOfSigning: new Date(),
        allowedByHR: true,
        template: {},
        shifts: [],
        signatures: {
          user: null,
          supervisorFirst: null,
          supervisorSecond: null,
        },
      };
    },
    allowedByHRChanged(v) {
      if (!v) {
        this.$refs.vacationHourly.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      }
    },
    getTemplates() {
      this.$apiService.get(`/templates/vacation-hourly`).then((res) => {
        this.vacationHourly.template = res.data || {};
      });
    },
    getEmployees(search) {
      this.employees = [];
      let url = `/users/employees`;

      if (search) {
        url += `?search=${search}`;
      }

      this.$apiService.get(url).then((res) => {
        this.employees = res.data;
      });
    },
    getVacationHourly() {
      this.resetVacation();
      this.$apiService.get(`/vacations-hourly/${this.$route.params.id}`).then((res) => {
        if (res.data) {
          let copy = JSON.parse(JSON.stringify(res.data));
          this.preEditVh = copy;
          this.vacationHourly = res.data;
          this.vacationHourly.dateFrom = new Date(res.data.dateFrom);
          this.vacationHourly.dateTo = new Date(res.data.dateTo);

          let showHRPanel = this.vh.signatures.supervisorFirst ? true : false;

          if (this.vh.type === '0030' || this.vh.type === '0016') {
            showHRPanel = this.vh.signatures.user ? showHRPanel : false;
          }
          if (this.vh.type === '0025' || this.vh.type === '0012') {
            showHRPanel = this.vh.signatures.user && this.vh.signatures.supervisorFirst;
          }

          if (
            this.vh.needsSecondConfirmation &&
            (!this.vh.signatures.supervisorSecond || !this.vh.allowedBySupervisorSecond)
          ) {
            showHRPanel = false;
          }

          if (this.activeCompany === 'КЦМ 2000') {
            showHRPanel = false;
          }

          this.$set(this.vh, 'showHRPanel', showHRPanel);
        }
      });
    },
    autoSelectDateTo() {
      if (this.vh.dateFrom) {
        this.vh.dateTo = new Date(this.vh.dateFrom);
      }
    },
    getShiftHours(d) {
      this.$apiService.get(`/schedule-groups/shift-hours/${d.shift.name}/${d.fullDate}`).then((res) => {
        if (res.data) {
          this.$set(d.shift, 'hours', d.shift.name === '7' ? '0800-1545' : res.data.hours);
          this.$set(d.shift, 'hoursCount', res.data.hoursCount);
          this.$set(d.shift, 'hoursCountNight', res.data.hoursCountNight);
        }
      });
    },
    getShifts() {
      this.dates = [];
      if (this.vh.userSchedule && (this.vh.date || (this.vh.dateFrom && this.vh.dateTo))) {
        let url = '/days/period/';

        if (this.vh.date) {
          url += this.vh.date;
        } else {
          url += `${this.vh.dateFrom}/${this.vh.dateTo}`;
        }

        this.$apiService.get(url).then((res) => {
          this.dates = res.data;
          this.loading = true;

          if (this.dates.length) {
            const promises = [];
            for (let d of this.dates) {
              let url = `/schedule-groups/${this.vh.userSchedule}/${d.fullDate}`;

              promises.push(
                new Promise((resolve, reject) => {
                  this.$apiService
                    .get(url)
                    .then((res) => {
                      this.$set(d, 'scheduleGroup', res.data);
                      this.generatePattern(d);

                      let url = `/schedule-groups/${this.vh.userSchedule}/${d.fullDate}/users?user=${this.vh.user._id}&ignoreRoles=1`;
                      url += `&weekend=${d.weekend}`;
                      url += `&shift=${d.shift ? true : false}`;

                      this.$apiService
                        .get(url)
                        .then((res) => {
                          if (res.data) {
                            const x = res.data[0];
                            if (x) {
                              if (!d.shift && (x.vacations || x.absence)) this.$set(d, 'shift', {});

                              if (x.vacations) {
                                this.$set(d.shift, 'hours', '');
                                this.$set(d.shift, 'name', 'О');
                                this.$set(d.shift, 'i', 'vacation');
                              } else if (x.absence) {
                                this.$set(d.shift, 'hours', '');
                                if (x.absence === 'shift-change') {
                                  this.$set(d.shift, 'i', 'orange');
                                  this.$set(d.shift, 'name', x.shift);

                                  this.getShiftHours(d);
                                } else {
                                  const types = {
                                    illness: 'Б',
                                    maternity: 'М',
                                    'self-release': 'С',
                                  };
                                  this.$set(d.shift, 'i', x.absence);
                                  this.$set(d.shift, 'name', types[x.absence]);
                                }
                              }
                            }

                            resolve();
                          }
                        })
                        .catch((err) => reject(err));
                    })
                    .catch((err) => reject(err));
                })
              );
            }

            Promise.all(promises)
              .then(() => {
                this.vh.shifts = this.dates.map((d) => d.shift);
                this.loading = false;
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
      }
    },
    generatePattern(d) {
      const firstDayOfScheduleGroup = new Date(d.scheduleGroup.dateFrom);
      const date = new Date(d.fullDate);
      const difference = Math.ceil(Math.abs(date - firstDayOfScheduleGroup) / (1000 * 60 * 60 * 24));
      let offset = 1;
      if (firstDayOfScheduleGroup < date) {
        offset = difference % d.scheduleGroup.pattern.length;
      }

      const sc = d.scheduleGroup.schedules;
      let position = offset ? offset - 1 : sc.pattern.length - 1;

      if (d.scheduleGroup.skipHolidays && d.holiday) {
        this.$set(d, 'shift', null);
      } else {
        this.$set(d, 'shift', d.scheduleGroup.shifts[sc.pattern[position] - 1]);
        if (d.shift) {
          this.$set(d.shift, 'i', sc.pattern[position]);
        }
      }
    },
    employeeInput() {
      if (!this.vh.user) {
        this.$set(this.vh, 'supervisorFirst', null);
        this.$set(this.vh, 'supervisorSecond', null);
        this.$set(this.vh, 'shifts', []);
        this.dates = [];
      }
    },
    employeeSelected() {
      if (!this.vh.user.supervisorFirst || !this.vh.user.supervisorSecond) {
        if (this.activeCompany === 'КЦМ 2000') this.$router.push('/vacations-hourly');
        return this.$snotify.error('Потребителят трябва да има първо и второ ниво одобрявващ.');
      }
      if (this.vh.user.schedule) {
        this.$set(this.vh, 'userSchedule', this.vh.user.schedule);
      }

      this.$set(this.vh, 'company', this.activeCompany);
      this.$set(this.vh, 'supervisorFirst', this.vh.user.supervisorFirst);
      this.$set(this.vh, 'supervisorSecond', this.vh.user.supervisorSecond);

      this.$apiService.get(`/users/${this.vh.user.supervisorFirst}?name=true`).then((res) => {
        this.$set(this.vh, 'supervisorFirst', res.data);
      });

      this.$apiService.get(`/users/${this.vacationHourly.user.supervisorSecond}?name=true`).then((res) => {
        this.$set(this.vh, 'supervisorSecond', res.data);
      });

      this.getShifts();
    },
    isValid() {
      if (!this.vh._id) {
        if (!this.vh.user) {
          this.$snotify.error('Не сте избрали служител');
          return false;
        }
        if (!this.vh.dateFrom) {
          this.$snotify.error('Не сте избрали начална дата на отсъствието');
          return false;
        }
        if (!this.vh.dateTo) {
          this.$snotify.error('Не сте избрали крайна дата на отсъствието');
          return false;
        }
        if (this.activeCompany !== 'КЦМ 2000' && (this.vh.type === '0025' || this.vh.type === '0012')) {
          if (!this.vh.signatures.user && this.regularEmployee) {
            this.$snotify.error('Служителят не се е подписал');
            return false;
          }
          if (!this.vh.reasonForVacation) {
            this.$snotify.error('Не сте въвели причина за отсъствието');
            return false;
          }
        }
        if (this.vh.type === '0020' || this.vh.type === '0019' || this.vh.type === '0025' || this.vh.type === '0030') {
          if (!this.vh.hourFrom || !this.vh.hourTo) {
            this.$snotify.error('Изборът на часови период е задължителен');
            return false;
          }
          if (!this.currentUser.roles.hr && !this.currentUser.roles.admin) {
            if (this.vh.type === '0030') {
              if (!this.vh.signatures.user && this.regularEmployee) {
                this.$snotify.error('Служителят не се е подписал');
                return false;
              }
            } else {
              if (
                (this.vh.type === '0025' ? !this.isOwnVh : true) &&
                !this.vh.signatures.supervisorFirst &&
                this.activeCompany !== 'КЦМ 2000' &&
                !this.regularEmployee
              ) {
                this.$snotify.error('Ръководителят не се е подписал');
                return false;
              }
            }
          }
        } else {
          if (this.vh.type === '0012' && this.activeCompany !== 'КЦМ 2000') {
            if (!this.isOwnVh && !this.isAdminOrHr && !this.vh.signatures.supervisorFirst) {
              this.$snotify.error('Ръководителят не се е подписал');
              return false;
            }
          } else {
            if (
              !this.vh.signatures.supervisorFirst &&
              !this.currentUser.roles.hr &&
              !this.currentUser.roles.admin &&
              this.activeCompany !== 'КЦМ 2000'
            ) {
              this.$snotify.error('Ръководителят не се е подписал');
              return false;
            }
          }
        }
      }
      return true;
    },
    create() {
      const dateFrom = new Date(this.vh.dateFrom);
      const dateTo = new Date(this.vh.dateTo);
      dateFrom.setHours(12, 0, 0, 0);
      dateTo.setHours(12, 0, 0, 0);

      if (dateFrom > dateTo) {
        this.$snotify.error('Началната дата не може да е по-голяма от крайната');
        return;
      }

      if (this.isValid()) {
        this.loading = true;

        this.$apiService
          .post('/vacations-hourly', this.vh)
          .then(() => {
            this.success = true;
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data.message) {
              this.$snotify.error(err.response.data.message);
            }
            this.success = false;
          })
          .then(() => {
            setTimeout(() => {
              if (this.success) {
                this.$snotify.success('Отсъствието е запазено успешно');
                this.$router.push('/vacations-hourly');
              } else {
                this.loading = false;
                this.success = undefined;
              }
            }, 1000);
          });
      }
    },
    deleteReports() {
      this.$apiService
        .delete(`/reports/${this.vh._id}`)
        .then(() => {
          this.$snotify.success('Бяха изтрити всички отчети в този период');
        })
        .catch((err) => {
          if (err.response.data.message) {
            this.$snotify.error(err.response.data.message);
          }
        });
    },
    update(type) {
      if (type === 'user') {
        if (!this.vh.signatures.user) {
          return this.$snotify.error('Служителят не се е подписал');
        }
      } else if (type === 'supervisor') {
        if (!this.vh.signatures.supervisorFirst) {
          return this.$snotify.error('Ръководителят не се е подписал');
        }
      } else if (type === 'supervisorSecond') {
        if (!this.vh.signatures.supervisorSecond) {
          return this.$snotify.error('Ръководителят не се е подписал');
        }
      } else if (type === 'hr') {
        if (!this.vh.allowedByHR) {
          return this.$snotify.error('Не може да запазите отсъствието в режим на редакция');
        }
      }

      this.loading = true;

      this.$apiService
        .put(`/vacations-hourly/${type}`, this.vh)
        .then(() => {
          this.success = true;
          if (this.vh.type === '0016' && type === 'annul' && this.success) this.deleteReports();
        })
        .catch((err) => {
          if (err.response.data.message) {
            this.$snotify.error(err.response.data.message);
          }
          this.success = false;
        })
        .then(() => {
          setTimeout(() => {
            if (this.success) {
              this.$snotify.success('Успешно запазено');
              this.$router.push('/vacations-hourly');
            } else {
              this.loading = false;
              this.success = undefined;
            }
          }, 1000);
        });
    },
    save(update) {
      if (this.vh._id) {
        if (update === 'annul') {
          if (
            !confirm(
              `Сигурни ли сте, че искате да анулирате отсъствието? ${
                this.vh.type === '0016' ? ' Всички отчети в този период ще бъдат изтрити' : ''
              }`
            )
          ) {
            return;
          }
          return this.update('annul');
        } else if (this.vh.type === '0016' || this.vh.type === '0030') {
          if (
            (this.vh.type === '0030' && this.vh.showHRPanel) ||
            (this.activeCompany === 'КЦМ 2000' && !this.regularEmployee && this.isAdminOrHr)
          ) {
            this.update('hr');
          } else if (this.vh.needsSecondConfirmation && this.vh.signatures.supervisorSecond) {
            this.update('supervisorSecond');
          } else if (this.regularEmployee || (this.vh.signatures.user && !this.vh.signatures.supervisorFirst)) {
            this.update('user');
          } else {
            this.update('supervisor');
          }
        } else if ((this.vh.showHRPanel || this.activeCompany === 'КЦМ 2000') && this.isAdminOrHr) {
          this.update('hr');
        } else {
          let type = 'supervisor';

          if (this.activeCompany !== 'КЦМ 2000' && (this.vh.type === '0025' || this.vh.type === '0012')) {
            if (!this.preEditVh.signatures.user && this.vh.signatures.user && this.vh.signatures.supervisorFirst)
              type = 'user';
          }

          if (
            this.vh.needsSecondConfirmation &&
            this.vh.signatures.supervisorSecond &&
            !this.preEditVh.signatures.supervisorSecond
          ) {
            type = 'supervisorSecond';
          }

          this.update(type);
        }
      } else {
        this.create();
      }
    },
    allowingDatesEdit() {
      this.allowDatesEdit = true;

      this.$refs.vacationHourly.scrollIntoView({ behavior: 'smooth' });
    },
    getDates() {
      let url = `/days/period/${this.vh.dateFrom}/${this.vh.dateTo}`;

      this.$apiService.get(url).then((res) => {
        this.datesPeriod = res.data;
        this.datesPeriod.forEach((element, index) => {
          this.$set(element, 'shift', this.vh.shifts[index]);

          for (let report of this.reports) {
            let reportDay = new Date(report.createdOn);
            let day = new Date(element.fullDate);

            let reportDaySetHours = new Date(day.setHours(0, 0, 0, 0));
            let daySetHours = new Date(reportDay.setHours(0, 0, 0, 0));

            if (reportDaySetHours.toString() === daySetHours.toString() && !element.report) {
              this.$set(element, 'report', report);
            }
          }
        });
      });
    },
    getReports(reportCreator) {
      if (reportCreator) {
        let url = '/reports';
        if (reportCreator) url += `?creator=${reportCreator._id}`;
        if (this.vh.dateFrom && this.vh.dateTo) url += `&dateFrom=${this.vh.dateFrom}&dateTo=${this.vh.dateTo}`;

        this.$apiService.get(url).then((res) => {
          this.reports = res.data;
          this.getDates();
        });
      } else {
        this.reports = [];
      }
    },
  },
  mounted() {
    if (this.$route.name === 'newVacationHourly') {
      if (this.regularEmployee || (this.activeCompany === 'КЦМ 2000' && this.currentUser.company === 'КЦМ 2000')) {
        if (this.activeCompany === 'КЦМ АД') {
          this.$set(this.vh, 'type', '0030');
        }
        this.$set(this.vh, 'user', this.currentUser);
        this.$router.push({ query: { type: this.vh.type } });
        this.employeeSelected();
      } else if (this.$route.query.type) {
        this.$set(this.vh, 'type', this.$route.query.type);
      }
      this.getTemplates();
    } else if (this.$route.name === 'editVacationHourly') {
      this.getVacationHourly();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
$contentWidth: 890px;
.vacation {
  align-items: center;
  color: #373737;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 100%;
}
.annulled-p {
  background-color: #d41717;
  border-radius: 2px;
  color: #fff;
  font-size: $px16;
  font-weight: 700;
  line-height: $px16;
  margin-bottom: 1rem;
  padding: 4px 5px 2px;
  text-align: center;
  width: $contentWidth;
}
.vacation__content {
  background-color: #f3f3f3;
  border-radius: 2px;
  border: 1px solid #d0d0d0;
  padding: 32px 105px;
  width: $contentWidth;

  header {
    display: flex;
    font-size: $px14;
    margin-bottom: 32px;
    width: 100%;

    &.header--reversed {
      justify-content: flex-end;
    }
  }
  &.reports-print {
    display: none;
    .vacation__content__title {
      text-align: center;
    }
    > section {
      display: flex;
      flex-direction: column;
      > p {
        margin-bottom: 5px;
        &:first-child {
          margin-top: 10px;
        }
        span {
          font-weight: bold;
          font-style: italic;
        }
      }
    }
  }
}
.vacation__content__title {
  font-size: $px16;
  font-weight: 700;
  letter-spacing: 0.369231rem;
  margin-bottom: 7px;
  // text-align: center;
  &.vacation__content__title--single {
    letter-spacing: initial;
  }
}
.vacation__content__subtitle {
  font-size: $px14;
  text-align: center;
  &.vacation__content__subtitle--textarea {
    margin: 2rem 0 1rem;
    text-align: left;
    white-space: pre-wrap;
  }
}
.vacation__content__mini-text {
  font-size: $px10;
  line-height: $px10;
}

.row {
  align-items: center;
  display: flex;
  &.row--align-top {
    align-items: flex-start;
  }
  &.row--align-bottom {
    align-items: flex-end;
  }
  &.row--margin-top {
    margin-top: 1rem;
  }
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  &.row--date-and-text {
    margin: 3rem 0;
  }
  &.row--report-button {
    justify-content: center;
    margin: 2rem 0;
  }
  &.row--space-between {
    justify-content: space-between;
    .date-input-wrapper,
    .schedule-viewer-wrapper {
      flex-basis: 0;
      flex-grow: 1;
    }
    .hours-input-wrapper {
      display: flex;
      justify-content: flex-end;
    }
    .date-input-wrapper {
      display: flex;
    }
    .schedule-viewer {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      label {
        color: #373737;
        display: inline-flex;
        font-size: $px11;
        font-weight: 700;
        margin-bottom: 0.5rem;
        width: 150px;
      }
      .fake-input {
        align-items: center;
        background-color: #fff;
        border: 1px solid #d3d3d3;
        border-radius: 2px;
        color: #373737;
        display: inline-flex;
        font-size: $px12;
        justify-content: center;
        height: 36px;
        overflow: auto;
        outline: none;
        padding: 0 $px12;
        width: 150px;
        &.fake-input--bigger {
          justify-content: initial;
        }

        @include scrollbar(3px, $lightAccent);
      }
    }
  }
  .half {
    width: 330px;
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
  .input-wrapper {
    width: 100%;
    &:not(:first-child) {
      margin-left: 1rem;
    }
    &.input-wrapper--100 {
      width: 100px;
    }
    &.input-wrapper--1 {
      width: 25%;
    }
    &.input-wrapper--2 {
      width: 50%;
    }
    &.input-wrapper--3 {
      width: 75%;
    }
  }
  .space--25 {
    width: 25%;
  }
  .texts--75 {
    font-size: $px14;
    margin-left: 1rem;
    padding-left: 10%;
    width: 75%;

    p {
      margin: 0.5rem 0;
    }
  }
  .vacation__content__title {
    margin: 0;
    &:not(:first-child) {
      margin-left: 0.5rem;
    }
    & + .input-wrapper {
      margin-left: 0.5rem;
    }
  }
}

.vacation__buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  width: $contentWidth;
  > div {
    align-items: center;
    display: flex;
    .button:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.vacation__content--order {
  margin-top: 20px;
  .vacation__content__subtitle {
    margin: 3rem 0 1rem;
  }
}

.vacation__content--second {
  // align-items: center;
  // display: flex;
  // flex-direction: column;
  margin-top: 20px;
  // text-align: center;
  // .permission-switch-wrapper {
  //   margin-bottom: 1.5rem;
  //   margin-top: 3rem;
  // }
}

.vacation__content--hr {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;
  .permission-switch-wrapper {
    margin-bottom: 1.5rem;
    margin-top: 3rem;
  }
}

.vacation__content--official-notice {
  margin-top: 20px;
}

.password-confirmation {
  border-radius: 2px;
  border: 1px solid #ababab;
  height: 30px;
  margin-right: 5px;
  outline: none;
  padding: 0 5px;
  text-align: center;
  width: 146px;
  @include transition($transition);
  &.invalid {
    box-shadow: 0px 0px 0px 1px $darkRed;
    border: 1px solid $darkRed;
  }
}

@media screen and (max-width: $sm) {
  .vacation {
    background-color: $mainColor;
    padding: 0;
    > div:last-of-type {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .annulled-p {
    margin-bottom: -1rem;
    padding-bottom: 1.25rem;
    width: 100%;
  }
  .vacation__content {
    border: 0;
    border-radius: 10px;
    padding: 9px 9px 20px;
    width: 100%;
  }
  .vacation__content--order,
  .vacation__content--official-notice,
  .vacation__content--hr {
    margin-top: 9px;
  }
  .vacation__buttons {
    background-color: #f3f3f3;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    // justify-content: center;
    margin: -1px 0 9px;
    padding: 20px 9px 25px;
    width: 100%;
    > div {
      .button {
        width: initial;
      }
      &:not(:first-child) {
        margin-left: 5px;
      }
    }
  }
  .row {
    &.row--mobile-wrap {
      flex-wrap: wrap;
    }
    &.row--mobile-space-between {
      justify-content: space-between;
    }
    &.row--mobile-equal-inputs {
      align-items: stretch;
      .input-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
      }
    }
    &.row--date-and-hours {
      flex-direction: column;
      & > * {
        width: 100%;
        &:not(:first-child) {
          margin-top: 1rem;
        }
        .input-wrapper,
        .schedule-viewer,
        .fake-input {
          width: 100% !important;
        }
        .schedule-viewer {
          label {
            width: 100%;
          }
        }
      }
    }
    .input-wrapper {
      &.input-wrapper--mobile-40 {
        width: 100%;
      }
      &.input-wrapper--mobile-order-3 {
        margin-left: 0;
        margin-top: 1rem;
        order: 3;
        width: 100%;
      }
    }
    .space--25 {
      display: none;
    }
  }
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .row {
    &.row--date-and-text {
      margin: 1.5rem 0;
      .input-wrapper {
        width: 80%;
      }
    }
    &.row--mobile-signatures {
      align-items: center;
      flex-direction: column;
      .signature-wrapper {
        &:not(:last-child) {
          margin-bottom: 9px;
        }
      }
    }
    &.row--mobile-column-center {
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .row {
        justify-content: center;
      }
      .permission-switch-wrapper {
        margin-bottom: 2rem;
      }
    }
    .texts--75 {
      padding: 0;
      width: 100%;
    }
  }
  .p--mobile-margin {
    margin: 1rem 0;
  }
}

@media screen and (max-width: $xs) {
  .vacation__buttons {
    flex-direction: column;
    > div {
      .button {
        width: initial;
      }
      &:not(:first-child) {
        margin-top: 5px;
        position: relative;
        .loading {
          margin-right: 5px;
          position: absolute;
          right: 100%;
          top: 50%;
          @include transform(translateY(-50%));
        }
      }
    }
  }
}

@media print {
  .vacation {
    padding: 0;
  }
  .annulled-p {
    width: 100%;
    border-radius: 0;
  }
  .vacation__content {
    background-color: initial;
    border: 1px solid #707070;
    border-radius: initial;
    padding: 20px 20px 40px;
    width: 100%;
    .vacation__content__subtitle {
      margin: 3rem 0;
    }
    &.reports-print {
      display: block;
    }
  }
  .row {
    &.row--print-center {
      justify-content: center;
    }
    &.row--print-margin {
      margin: 2rem 0;
    }
    .input-wrapper {
      &.input-wrapper--full {
        width: 100%;
      }
      &.input-wrapper--third {
        width: 50%;
      }
      &.input-wrapper--2 {
        width: 100%;
      }
      &.input-wrapper--3 {
        width: 100%;
      }
      &.input-wrapper--datepicker {
        width: initial;
      }
    }
    .space--25 {
      display: none;
    }
    .texts--75 {
      width: 100%;
    }
    &.row--space-between {
      .schedule-viewer-wrapper {
        display: flex;
        justify-content: flex-end;
      }
      .schedule-viewer {
        align-items: flex-end;
        display: inline-flex;
        flex-direction: row;
        label {
          margin-bottom: 0;
          margin-right: 0.5rem;
          width: initial;
          white-space: nowrap;
        }
        .fake-input {
          background-color: initial;
          border: none;
          border-bottom: 1px dashed #000;
          height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .print-add-margin-top {
    margin-top: 3rem;
  }
}
</style>
