<template>
  <div class="sidepanel__vacation">
    <main>
      <Button class="button--big button--grey" @click="backToAllVacationsHourly">
        <span class="icon">B</span>
        НАЗАД КЪМ ВСИЧКИ ОТСЪСТВИЯ
      </Button>
      <template v-if="loaded">
          <img src="@/assets/img/stages/ss-a.svg" v-if="vh.annulled" />

          <template v-else-if="vh.needsSecondConfirmation">
            <img src="@/assets/img/stages/ss-c.svg" v-if="!vh.allowedBySupervisorSecond" />
            <template v-else-if="vhType === '0030' || vhType === '0025'">
              <img src="@/assets/img/sidepanel/stages/s4-0.svg" v-if="!vh._id" />
              <img src="@/assets/img/sidepanel/stages/s4-4.svg" v-if="vh.finalizedAt" />
              <img src="@/assets/img/sidepanel/stages/s4-3.svg" v-else-if="vh.signatures?.supervisorSecond" />
              <img src="@/assets/img/sidepanel/stages/s4-2.svg" v-else-if="vh.signatures?.supervisorFirst" />
              <img src="@/assets/img/sidepanel/stages/s4-1.svg" v-else-if="vh.signatures?.user" />
            </template>
            <template v-else-if="vhType === '0012'">
              <img src="@/assets/img/sidepanel/stages/s3-0.svg" v-if="!vh._id" />
              <img src="@/assets/img/sidepanel/stages/s3-3.svg" v-else-if="vh.finalizedAt" />
              <img src="@/assets/img/sidepanel/stages/s3-2.svg" v-else-if="vh.signatures?.supervisorFirst && vh.signatures?.user" />
              <img src="@/assets/img/sidepanel/stages/s3-1.svg" v-else />
            </template>
            <template v-else-if="vhType === '0020'">
              <img src="@/assets/img/sidepanel/stages/s3-0.svg" v-if="!vh._id" />
              <img src="@/assets/img/sidepanel/stages/s3-3.svg" v-else-if="vh.finalizedAt" />
              <img src="@/assets/img/sidepanel/stages/s3-2.svg" v-else-if="vh.signatures?.supervisorSecond" />
              <img src="@/assets/img/sidepanel/stages/s3-1.svg" v-else />
            </template>
            <template v-else-if="vhType === '0045'">
              <img src="@/assets/img/stages/s2-0.svg" v-if="!vh._id" />
              <img src="@/assets/img/stages/s2-2.svg" v-else-if="vh.finalizedAt" />
              <img src="@/assets/img/stages/s2-1.svg" v-else />
            </template>
          </template>
          <template v-else-if="activeCompany === 'КЦМ 2000'">
            <template v-if="vhType === '0016' || vhType === '0030'">
              <img src="@/assets/img/stages/s2-0.svg" v-if="!vh._id" />
              <img src="@/assets/img/stages/s2-2.svg" v-else-if="vh.finalizedAt" />
              <img src="@/assets/img/stages/s2-1.svg" v-else-if="vh.signatures?.user" />
            </template>
            <template v-else>
              <img src="@/assets/img/stages/s1-1.svg" v-if="vh.finalizedAt" />
              <img src="@/assets/img/stages/s1-0.svg" v-else />
            </template>
          </template>
          <template v-else-if="vhType === '0030' || vhType === '0025'">
            <img src="@/assets/img/sidepanel/stages/s3-0.svg" v-if="!vh._id" />
            <img src="@/assets/img/sidepanel/stages/s3-3.svg" v-else-if="vh.finalizedAt" />
            <img src="@/assets/img/sidepanel/stages/s3-2.svg" v-else-if="vh.signatures?.supervisorFirst" />
            <img src="@/assets/img/sidepanel/stages/s3-1.svg" v-else />
          </template>
          <template v-else-if="vhType === '0012' || vhType === '0020' || vhType === '0016' || vhType === '0019'">
            <img src="@/assets/img/stages/s2-0.svg" v-if="!vh._id" />
            <img src="@/assets/img/stages/s2-2.svg" v-else-if="vh.finalizedAt" />
            <img src="@/assets/img/stages/s2-1.svg" v-else />
          </template>
          <template v-else-if="vhType === '0045' || vhType === '0046'">
            <img src="@/assets/img/stages/s1-0.svg" v-if="!vh._id" />
            <img src="@/assets/img/stages/s1-1.svg" v-else />
          </template>
      </template>
    </main>
    <template v-if="$route.name === 'newVacationHourly'">
      <Button class="button--big button--blue" @click="openFile('employees-po.mp4')" v-if="currentUser.roles.employee">
        <span class="icon">k</span>
        КАК ДА ПУСНА ЗАЯВЛЕНИЕ ЗА ПОЧАСОВО ОТСЪСТВИЕ?
      </Button>
      <Button
        class="button--big button--lighter-green"
        @click="openFile('supervisors-po.mp4')"
        v-if="currentUser.roles.supervisor"
      >
        <span class="icon">k</span>
        КАК ДА ОДОБРЯ ЗАЯВЛЕНИЕ ЗА ПОЧАСОВО ОТСЪСТВИЕ?
      </Button>
    </template>
  </div>
</template>

<script>
import Button from '../Button';
import { mapGetters } from 'vuex';
export default {
  components: {
    Button,
  },
  data() {
    return {
      vh: {},
      loaded: false,
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
    vhType() {
      return this.vh?.type || this.$route.query.type;
    },
  },
  watch: {
    $route: {
      handler: 'getVh',
      immediate: true,
    },
  },
  methods: {
    backToAllVacationsHourly() {
      this.$router.push('/vacations-hourly');
    },
    openFile(f) {
      const url = `${process.env.VUE_APP_STATIC_URI}/${f}`;
      window.open(url);
    },
    getVh() {
      if (this.$route.params.id) {
        this.$apiService
          .get(`/vacations-hourly/${this.$route.params.id}`)
          .then((res) => {
            if (res.data._id) {
              this.vh = res.data;
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .then(() => (this.loaded = true));
      } else {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.sidepanel__vacation {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 70px 10px 10px;

  .button:not(:first-child) {
    margin-top: 5px;
  }
  main {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;

    > img {
      display: block;
      margin: 6.5rem 0 6.5rem;
      width: 100px;
    }
  }
}
</style>
